<template>    
    <Dialog style="display: flex; overflow-x:auto;" modal :header="'Compañías del Baremo ' + nombre" :style="{ width: '800px'}">
        <h5><strong>Agregar compañía</strong></h5>
        <div style="display: flex; justify-content: space-between; margin-bottom: 25px;">
            <MultiSelect v-model="companiaSeleccionada" :options="companiasNoAgregadas" optionLabel="nombre" optionValue="id" placeholder="Seleccione compañía para agregar"
            :maxSelectedLabels="3" style="width: 90%;"/>
            <button type="button" class="btn btn-primary btn-sm" @click="agregarCompania()">Agregar</button>
        </div>

        <h5 style="margin-top: 20px;"><strong>Compañías agregadas</strong></h5>
        <div class="table-responsive">
            <table class="table table-striped">
                <tbody v-for="(compania, index) in companiasAgregadas" :key="compania.id">                    
                    <tr v-if="index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                        <td><h5>{{ compania.nombre }}</h5></td>
                        <td @click="eliminarCompania(compania)" style="text-align: end;"><button class="btn btn-danger btn-sm">Eliminar</button></td>
                    </tr>
                    <tr v-else style="background-color: white;">
                        <td><h5>{{ compania.nombre }}</h5></td>
                        <td @click="eliminarCompania(compania)" style="text-align: end;"><button class="btn btn-danger btn-sm">Eliminar</button></td>
                    </tr>
                    <tr v-if="compania.contratos && index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                        <td>
                            <select v-model="contratoSeleccionado[compania.id]" class="form-control">
                                <option value="">Seleccione Contrato para agregar</option>
                                <option v-for="contrato in compania.contratosNoAgregados" :key="contrato" :value="contrato">{{ contrato }}</option>
                            </select>
                        </td>
                        <td style="text-align: end;">
                            <button @click="agregarContrato(compania)" class="btn btn-sm btn-light" style="border:1px solid grey;">Añadir</button>
                        </td>
                    </tr>
                    <tr v-else-if="compania.contratos" style="background-color: white;">
                        <td>
                            <select v-model="contratoSeleccionado[compania.id]" class="form-control">
                                <option value="">Seleccione Contrato para agregar</option>
                                <option v-for="contrato in compania.contratosNoAgregados" :key="contrato" :value="contrato">{{ contrato }}</option>
                            </select>
                        </td>
                        <td style="text-align: end;">
                            <button @click="agregarContrato(compania)" class="btn btn-sm btn-light" style="border:1px solid grey;">Añadir</button>
                        </td>
                    </tr>
                    <tr v-if="compania.contratosAgregados && index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                        <td style="background-color: white;">
                            <tr style="display: flex; justify-content: space-between;" v-for="agregados in compania.contratosAgregados" :key="agregados">
                                <td>
                                    {{ agregados.contrato_id_contrato }}
                                </td>
                                <td>
                                    <button @click="eliminarContrato(compania, agregados)" class="btn btn-danger btn-sm">Eliminar</button>
                                </td>
                            </tr>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-else-if="compania.contratosAgregados" style="background-color: white;">
                        <td style="background-color: rgba(0, 0, 0, .05);">
                            <tr style="display: flex; justify-content: space-between;" v-for="agregados in compania.contratosAgregados" :key="agregados">
                                <td>
                                    {{ agregados.contrato_id_contrato }}
                                </td>
                                <td>
                                    <button @click="eliminarContrato(compania, agregados)" class="btn btn-danger btn-sm">Eliminar</button>
                                </td>
                            </tr>
                        </td>
                        <td></td>
                    </tr>   
                </tbody>
            </table>
        </div>
    </Dialog>
</template>


<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
export default {
    props: ["id", "nombre"],
    data() {
        return {
            datos:'',
            contratoSeleccionado:[],
            companias:[],
            companiasAgregadas:[],
            companiasNoAgregadas:[],
            companiaSeleccionada:'',
        };
    },
    components: {
        Dialog,
        MultiSelect,
    },
    computed: {  
    },    
    methods: {
        async eliminarContrato(compania, contrato){
            const api = new PwgsApi();
            await api.deletebody('baremos-pwgs/'+this.$props.id+'/compania/'+compania.id+'/contrato',{id_contrato:contrato.id_baremo_relacion_contrato});
            this.cargarDatos();
            this.obtenerCompanias();
        },
        async agregarContrato(compania){
            if(this.contratoSeleccionado[compania.id]==''){
                alert('Debe seleccionar un contrato');
                return;
            }
            const api = new PwgsApi();
            await api.post('baremos-pwgs/'+this.$props.id+'/compania/'+compania.id+'/contratos', {contratos:[this.contratoSeleccionado[compania.id]]});
            this.contratoSeleccionado[compania.id] = '';
            this.cargarDatos();
            this.obtenerCompanias();
            this.$emit('cambiolista');
        },
        async eliminarCompania(compania){
            const api = new PwgsApi();
            await api.delete('baremos-pwgs/'+this.$props.id+'/compania/'+compania.id);

            this.cargarDatos();
            this.obtenerCompanias();
            this.$emit('cambiolista');
        },
        async agregarCompania(){
            if(this.companiaSeleccionada==""){ 
                alert('Debe seleccionar una compañía');
                return;
            }
            const api = new PwgsApi();
            await api.post('baremos-pwgs/'+this.$props.id+'/companias',{ids_companias:this.companiaSeleccionada});
            
            this.companiaSeleccionada = '';
            this.cargarDatos();
            this.obtenerCompanias();    
            this.$emit('cambiolista');     
        },
        async comprobarContratos(){
            for(var i in this.companiasAgregadas) {
                var id = this.companiasAgregadas[i].id;
                var contratos = this.datos.companias_contratos_disponibles[id];
                if(contratos.length > 0) {
                    this.contratoSeleccionado[id] = '';
                    this.companiasAgregadas[i].contratos = contratos;
                    var contratosAgregados = this.datos.contratos[id];
                    var id_contratos = this.datos.id_contratos[id];
                    if(contratosAgregados){
                        this.companiasAgregadas[i].contratosAgregados = contratosAgregados;
                        this.companiasAgregadas[i].contratosNoAgregados = contratos.filter(contrato => 
                            !id_contratos.includes(contrato)
                        );
                    }else{                        
                        this.companiasAgregadas[i].contratosNoAgregados = contratos;
                    }
                    
                }
            }
        },
        async cargarDatos() {
            const api = new PwgsApi();            
            this.datos = await api.get('baremos-pwgs/'+this.id);
        },
        async obtenerCompanias() {
            const api = new PwgsApi();
            const response = await api.get('/companias/simple?sortField=nombre&sortOrder=1');
            this.companias = response.datos;
            console.log('dats', this.datos);
            var idCompaniasAgregadas = this.datos.id_companyias;
            this.companiasAgregadas = this.companias.filter(compania => 
                idCompaniasAgregadas.includes(compania.id)
            );
            this.companiasNoAgregadas = this.companias.filter(compania => 
                !idCompaniasAgregadas.includes(compania.id)
            );
            this.comprobarContratos();   
        },
    },
    mounted() {
        this.cargarDatos();
    },
    watch: {
        datos(){
            this.obtenerCompanias();
        }
    },
}
</script>
<style scoped>
</style>