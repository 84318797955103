<template>    
    <Dialog style="display: flex; overflow-x:auto;" modal :header="'Operarios del Baremo ' + nombre" :style="{ width: '1200px'}">
        <h5><strong>Agregar operario</strong></h5>
        <div style="display: flex; justify-content: space-between; margin-bottom: 25px;">
            <MultiSelect v-model="operarioSeleccionado" :options="operariosNoAgregados" optionLabel="nombre" optionValue="id" placeholder="Seleccione operario para agregar"
            :maxSelectedLabels="3" style="width: 90%;"/>
            <button type="button" class="btn btn-primary btn-sm" @click="agregarOperario()">Agregar</button>
        </div>

        <h5 style="margin-top: 20px;"><strong>Operarios agregados</strong></h5>
        <div class="table-responsive">
            <table class="table table-striped">
                <tbody v-for="(operario, index) in operariosAgregados" :key="operario.id">
                    <tr v-if="index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                        <td><h5>{{ operario.nombre }}</h5></td>
                        <td v-if="!operario.mostrarCompanias == true && !operario.companiasAgregadas"><button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="mostrarCompanias(operario.id)">Compañías</button></td>
                        <td v-else>
                                <h7><strong>Agregar compañía</strong></h7>
                                <div style="display: flex; justify-content: space-between; margin-bottom: 25px; align-items: center;">
                                    <MultiSelect v-model="operario.companiaSeleccionada" :options="operario.companiasNoAgregadas" optionLabel="nombre" optionValue="id" placeholder="Seleccione compañía para agregar"
                                    :maxSelectedLabels="3" style="width: 80%;" class="multiselect-small"/>
                                    <button type="button" class="btn btn-primary btn-sm" @click="agregarCompania(operario, operario.id)">Agregar</button>
                                </div>

                                <h7 style="margin-top: 20px;"><strong>Compañías agregadas</strong></h7>
                                <table class="table table-striped">
                                    <tbody v-for="(compania, index) in operario.companiasAgregadas" :key="compania.id">                    
                                        <tr v-if="index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                                            <td><h5>{{ compania.nombre }}</h5></td>
                                            <td @click="eliminarCompania(operario.id, compania)" style="text-align: end;"><button class="btn btn-danger btn-sm">Eliminar</button></td>
                                        </tr>
                                        <tr v-else style="background-color: white;">
                                            <td><h5>{{ compania.nombre }}</h5></td>
                                            <td @click="eliminarCompania(operario.id, compania)" style="text-align: end;"><button class="btn btn-danger btn-sm">Eliminar</button></td>
                                        </tr>
                                        


                                        <tr v-if="compania.contratos && index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                                            <td>
                                                <select v-model="compania.contratoSeleccionado" class="form-control">
                                                    <option value="">Seleccione Contrato para agregar</option>
                                                    <option v-for="contrato in compania.contratosNoAgregados" :key="contrato" :value="contrato">{{ contrato }}</option>
                                                </select>
                                            </td>
                                            <td style="text-align: end;">
                                                <button @click="agregarContrato(operario.id, compania)" class="btn btn-sm btn-light" style="border:1px solid grey;">Añadir</button>
                                            </td>
                                        </tr>
                                        <tr v-else-if="compania.contratos" style="background-color: white;">
                                            <td>
                                                <select v-model="compania.contratoSeleccionado" class="form-control">
                                                    <option value="">Seleccione Contrato para agregar</option>
                                                    <option v-for="contrato in compania.contratosNoAgregados" :key="contrato" :value="contrato">{{ contrato }}</option>
                                                </select>
                                            </td>
                                            <td style="text-align: end;">
                                                <button @click="agregarContrato(operario.id, compania)" class="btn btn-sm btn-light" style="border:1px solid grey;">Añadir</button>
                                            </td>
                                        </tr>

                                        
                                        <tr v-if="compania.contratosAgregados && index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                                            <td style="background-color: white;">
                                                <tr style="display: flex; justify-content: space-between;" v-for="agregados in compania.contratosAgregados" :key="agregados">
                                                    <td>
                                                        {{ agregados.contrato_id_contrato }}
                                                    </td>
                                                    <td>
                                                        <button @click="eliminarContrato(operario.id, compania, agregados)" class="btn btn-danger btn-sm">Eliminar</button>
                                                    </td>
                                                </tr>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr v-else-if="compania.contratosAgregados" style="background-color: white;">
                                            <td style="background-color: rgba(0, 0, 0, .05);">
                                                <tr style="display: flex; justify-content: space-between;" v-for="agregados in compania.contratosAgregados" :key="agregados">
                                                    <td>
                                                        {{ agregados.contrato_id_contrato }}
                                                    </td>
                                                    <td>
                                                        <button @click="eliminarContrato(operario.id, compania, agregados)" class="btn btn-danger btn-sm">Eliminar</button>
                                                    </td>
                                                </tr>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            
                        </td>
                        <td  style="text-align: end;"><button class="btn btn-danger btn-sm" @click="eliminarOperario(operario)">Eliminar</button></td>
                    </tr>
                    <tr v-else style="background-color: white;">
                        <td><h5>{{ operario.nombre }}</h5></td>
                        <td v-if="!operario.mostrarCompanias == true && !operario.companiasAgregadas"><button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="mostrarCompanias(operario.id)">Compañías</button></td>
                        <td v-else>
                                <h7><strong>Agregar compañía</strong></h7>
                                <div style="display: flex; justify-content: space-between; margin-bottom: 25px; align-items: center;">
                                    <MultiSelect v-model="operario.companiaSeleccionada" :options="operario.companiasNoAgregadas" optionLabel="nombre" optionValue="id" placeholder="Seleccione compañía para agregar"
                                    :maxSelectedLabels="3" style="width: 80%;" class="multiselect-small"/>
                                    <button type="button" class="btn btn-primary btn-sm" @click="agregarCompania(operario, operario.id)">Agregar</button>
                                </div>

                                <h7 style="margin-top: 20px;"><strong>Compañías agregadas</strong></h7>
                                <table class="table table-striped">
                                    <tbody v-for="(compania, index) in operario.companiasAgregadas" :key="compania.id">                    
                                        <tr v-if="index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                                            <td><h5>{{ compania.nombre }}</h5></td>
                                            <td @click="eliminarCompania(operario.id, compania)" style="text-align: end;"><button class="btn btn-danger btn-sm">Eliminar</button></td>
                                        </tr>
                                        <tr v-else style="background-color: white;">
                                            <td><h5>{{ compania.nombre }}</h5></td>
                                            <td @click="eliminarCompania(operario.id, compania)" style="text-align: end;"><button class="btn btn-danger btn-sm">Eliminar</button></td>
                                        </tr>
                                        


                                        <tr v-if="compania.contratos && index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                                            <td>
                                                <select v-model="compania.contratoSeleccionado" class="form-control">
                                                    <option value="">Seleccione Contrato para agregar</option>
                                                    <option v-for="contrato in compania.contratosNoAgregados" :key="contrato" :value="contrato">{{ contrato }}</option>
                                                </select>
                                            </td>
                                            <td style="text-align: end;">
                                                <button @click="agregarContrato(operario.id, compania)" class="btn btn-sm btn-light" style="border:1px solid grey;">Añadir</button>
                                            </td>
                                        </tr>
                                        <tr v-else-if="compania.contratos" style="background-color: white;">
                                            <td>
                                                <select v-model="compania.contratoSeleccionado" class="form-control">
                                                    <option value="">Seleccione Contrato para agregar</option>
                                                    <option v-for="contrato in compania.contratosNoAgregados" :key="contrato" :value="contrato">{{ contrato }}</option>
                                                </select>
                                            </td>
                                            <td style="text-align: end;">
                                                <button @click="agregarContrato(operario.id, compania)" class="btn btn-sm btn-light" style="border:1px solid grey;">Añadir</button>
                                            </td>
                                        </tr>

                                        
                                        <tr v-if="compania.contratosAgregados && index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                                            <td style="background-color: white;">
                                                <tr style="display: flex; justify-content: space-between;" v-for="agregados in compania.contratosAgregados" :key="agregados">
                                                    <td>
                                                        {{ agregados.contrato_id_contrato }}
                                                    </td>
                                                    <td>
                                                        <button @click="eliminarContrato(operario.id, compania, agregados)" class="btn btn-danger btn-sm">Eliminar</button>
                                                    </td>
                                                </tr>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr v-else-if="compania.contratosAgregados" style="background-color: white;">
                                            <td style="background-color: rgba(0, 0, 0, .05);">
                                                <tr style="display: flex; justify-content: space-between;" v-for="agregados in compania.contratosAgregados" :key="agregados">
                                                    <td>
                                                        {{ agregados.contrato_id_contrato }}
                                                    </td>
                                                    <td>
                                                        <button @click="eliminarContrato(operario.id, compania, agregados)" class="btn btn-danger btn-sm">Eliminar</button>
                                                    </td>
                                                </tr>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            
                        </td>
                        <td  style="text-align: end;"><button class="btn btn-danger btn-sm" @click="eliminarOperario(operario)">Eliminar</button></td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </Dialog>
</template>


<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
export default {
    props: ["id", "nombre"],
    data() {
        return {
            datos:'',
            contratoSeleccionado:[],
            companiaSeleccionada:[],
            operarios:'',
            operariosAgregados:'',
            operariosNoAgregados:[],
            operarioSeleccionado:'',
        };
    },
    components: {
        Dialog,
        MultiSelect,
    },
    computed: {  
    },    
    methods: {
        async eliminarContrato(idoperario, compania, contrato){
            const api = new PwgsApi();
            await api.deletebody('baremos-pwgs/'+this.$props.id+'/operario/'+idoperario+'/contrato',{id_contrato:contrato.id_baremo_relacion_contrato});
            this.cargarDatos();
            this.obtenerOperarios();   
        },
        async agregarContrato(idoperario, compania){
            if(compania.contratoSeleccionado==''){
                alert('Debe seleccionar un contrato');
                return;
            }
            const api = new PwgsApi();
            await api.post('baremos-pwgs/'+this.$props.id+'/operario/'+idoperario+'/contratos', {id_compania:compania.id,contratos:[compania.contratoSeleccionado]});
            compania.contratoSeleccionado = '';
            this.cargarDatos();
            this.obtenerOperarios();   
            this.$emit('cambiolista');
        },
        async agregarOperario(){
            if(this.operarioSeleccionado==""){ 
                alert('Debe seleccionar un operario');
                return;
            }
            const api = new PwgsApi();
            await api.post('baremos-pwgs/'+this.$props.id+'/operarios',{ids_operarios:this.operarioSeleccionado});            
            
            this.operarioSeleccionado = '';
            this.cargarDatos();
            this.obtenerOperarios();    
            this.$emit('cambiolista');   
        },
        async eliminarOperario(operario){
            const api = new PwgsApi();
            await api.delete('baremos-pwgs/'+this.$props.id+'/operario/'+operario.id);          

            this.cargarDatos();
            this.obtenerOperarios();
            this.$emit('cambiolista');
        },
        async eliminarCompania(idoperario, compania){
            const api = new PwgsApi();
            await api.deletebody('baremos-pwgs/'+this.$props.id+'/operario/'+idoperario+'/compania',{id_compania:compania.id});

            this.cargarDatos();
            this.obtenerCompanias();
            this.$emit('cambiolista');
        },
        async agregarCompania(operario, idoperario){
            console.log(idoperario);
            if(operario.companiaSeleccionada==""){ 
                alert('Debe seleccionar una compañía');
                return;
            }
            const api = new PwgsApi();
            await api.post('baremos-pwgs/'+this.$props.id+'/operario/'+idoperario+'/companias',{ids_companias:operario.companiaSeleccionada});            
            
            operario.companiaSeleccionada = '';
            this.cargarDatos();
            this.obtenerOperarios();    
            this.$emit('cambiolista');    
        },
        async comprobarContratos(){
            for(var i in this.companiasAgregadas) {
                var id = this.companiasAgregadas[i].id;
                var contratos = this.datos.companias_contratos_disponibles[id];
                if(contratos.length > 0) {
                    this.contratoSeleccionado[id] = '';
                    this.companiasAgregadas[i].contratos = contratos;
                    var contratosAgregados = this.datos.contratos[id];
                    var id_contratos = this.datos.id_contratos[id];
                    if(contratosAgregados){
                        this.companiasAgregadas[i].contratosAgregados = contratosAgregados;
                        this.companiasAgregadas[i].contratosNoAgregados = contratos.filter(contrato => 
                            !id_contratos.includes(contrato)
                        );
                    }else{                        
                        this.companiasAgregadas[i].contratosNoAgregados = contratos;
                    }
                    
                }
            }
        },
        async cargarDatos() {
            const api = new PwgsApi();            
            this.datos = await api.get('baremos-pwgs/'+this.id);
        },
        async obtenerCompanias() {
            const api = new PwgsApi();
            const response = await api.get('/companias/simple?sortField=nombre&sortOrder=1');
            this.companias = response.datos;  
        },
        mostrarCompanias(idoperario){
            let operarioEncontrado = this.operariosAgregados.find(operario => operario.id === idoperario);
            operarioEncontrado.companiaSeleccionada = '';
            operarioEncontrado.mostrarCompanias = !operarioEncontrado.mostrarCompanias;
        },
        async obtenerOperarios() {
            const api = new PwgsApi();
            const response = await api.get('/operarios/simple?sortField=nombre&sortOrder=1');
            this.operarios = response.datos;
            var idOperariosAgregados = this.datos.id_operarios;
            this.operariosAgregados = this.operarios.filter(operario => 
                idOperariosAgregados.includes(operario.id)
            );
            this.operariosNoAgregados = this.operarios.filter(operario => 
                !idOperariosAgregados.includes(operario.id)
            );
            for(let operario of this.operariosAgregados) {
                var id = operario.id;                
                let operarioEncontrado = this.operariosAgregados.find(operario => operario.id === id);
                operarioEncontrado.companiaSeleccionada = '';
                operarioEncontrado.mostrarCompanias = false;                
                var companias_contratos = this.datos.operarios_companias_contratos_disponibles[id];
                if(companias_contratos){
                    var ids_companias = Object.keys(companias_contratos);
                    operarioEncontrado.companiasAgregadas = this.companias.filter(compania => 
                        ids_companias.includes(compania.id)
                    );
                    operarioEncontrado.companiasNoAgregadas = this.companias.filter(compania => 
                        !ids_companias.includes(compania.id)
                    );
                    for(var i in operarioEncontrado.companiasAgregadas){
                        var id2 = operarioEncontrado.companiasAgregadas[i].id;
                        var contratos = companias_contratos[id2];
                        if(contratos.length > 0) {
                            operarioEncontrado.companiasAgregadas[i].contratoSeleccionado = '';
                            operarioEncontrado.companiasAgregadas[i].contratos = contratos;
                            try{
                                var contratosAgregados = this.datos.contratos_operarios[id][id2];                            
                                var id_contratos = this.datos.id_contratos_operarios[id][id2];                                
                                operarioEncontrado.companiasAgregadas[i].contratosAgregados = contratosAgregados;
                                operarioEncontrado.companiasAgregadas[i].contratosNoAgregados = contratos.filter(contrato => 
                                    !id_contratos.includes(contrato)
                                );
                            }
                            catch(e){
                                operarioEncontrado.companiasAgregadas[i].contratosNoAgregados = contratos;
                            }
                            
                        }
                    }
                }else{
                    operarioEncontrado.companiasNoAgregadas = this.companias;
                }
            }        
        },
    },
    mounted() {
        this.cargarDatos();
    },
    watch: {
        datos(){
            this.obtenerCompanias();
            this.obtenerOperarios();
        }
    },
}
</script>
<style scoped>
</style>